$(document).ready(function(){
    if (document.getElementsByClassName('productCarouselItem')) {
        $('.productCarouselItem').slick({
            slidesToShow: 6,
            infinite: false,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',

            responsive: [
                {
                    breakpoint: 1462,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        dots: false,
                        arrows: false,
                        slidesToShow: 2,
                        vertical:false,
                        slidesToScroll: 1,
                        autoplay: true
                    }
                }
            ]
        });
        $('.productCarouselItem').removeClass('skeleton-loaderCarousel');
    }

    if (document.getElementsByClassName('steps-cards')) {
        $('.steps-cards').slick({
            slidesToShow: 5,
            infinite: true,
            slidesToScroll: 1,
            arrows: false,

            responsive: [
                {
                    breakpoint: 3000,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1

                    }
                }
            ]
        });
    }

    if (document.getElementById('product-carousel')) {
        $(document.getElementsByTagName('div')).slick({
            slidesToShow: 4,
            infinite: true,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',

            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        slidesToScroll: 1

                    }
                }
            ]
        });
    }
});
